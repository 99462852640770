

.modalFormContainer {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000; /* Fallback color: solid black */
  background-color: rgba(0, 0, 0, 0.563); /* Semi-transparent black for supporting browsers */
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  text-align: center;
  width: 100%;
  height: 100%;
  align-self: center;
  /* margin: 100px auto; */
}

.modalFormContent {
  background-color: #F4F4F7;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.testimonialQuote {
  font-size: large;
  font-weight: bold;
  color: #F4F4F7;
  background-color: #0F5D66;
}

/* Base styles for the testimonial section */
.testimonialSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  align-self: center;
  margin: auto;
  background-color: #0F5D66;
  box-sizing: border-box;
  border-radius: 15px;
  width: 50%; /* Default to 50% width on desktop */
  max-width: 600px; /* Max-width to control the size on larger screens */
}



.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 70%;
  align-self: center;
  margin: auto;
  box-sizing: border-box;
}

.topSection .modalOpenButton, 
.featuresSection .modalOpenButton {
  background-color: #31B099;
  font-weight: bold;
  border-width: 0;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
}

.topSection .modalOpenButton:hover, 
.featuresSection .modalOpenButton:hover {
  background-color: #2A8A7F;
}

.modalFormButton {
  background-color: #31B099;
  font-weight: bold;
  border-width: 0;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 25px;
}

.modalFormButton:hover {
  background-color: #2A8A7F;
}

/* Modal for paragraph */
.modalFormContainer p {
  text-align: center;
  font-size: 1.2rem;
}

.modalFormContent form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1B2632;
  font-size: 10px;
  text-align: left;
}

.modalFormContent h2 {
  color: #1B2632;
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.modalFormContent label p {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
  /* modern styling for error messages */
  color: #ff4242bc;
  font-style: italic;

}

.modalFormContent p {
  color: #1B2632;
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;

}

.modalFormContent label {
  font-weight: bold;
}
/* adujust size and width of form inputs */
.modalFormContent input, textarea {
  /* width: 100%; */
  width: auto;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #1B2632;
  box-sizing: border-box;
}


.heroHeading h1, 
.heroHeading h2, 
.heroHeading h3, 
.heroHeading h4, 
.heroHeading h5, 
.heroHeading h6 {
  color: #1B2632;
}

.topSection .heroSubheading {
  width: 90%;
  max-width: 1000px;
}


.topSection .heroParagraph p {
  color: #6C7278;
  font-size: 18px;
  max-width: 80%;
  margin: auto;
  text-align: center;
}

.heroImageContainer img, .featureImageContainer img{
  width: 80%;
  height: auto;
  max-width: 500px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  display: block;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.featureDetails .modalOpenButton {
  background-color: #E8E8EC;
  border-width: 0;
  color: #1A1C1E;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 25px;
}

.featureDetails .modalOpenButton:hover {
  background-color: #D9D9E2;
}

.subHeading h1, 
.subHeading h2, 
.subHeading h3, 
.subHeading h5, 
.subHeading h6 {
  color: #1B2632;
  max-width: 80%;
  margin: auto;
  padding: 15px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #f9f9f9;
}

.modalCloseButton{
  border-width: 0;
  color: #1A1C1E;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 30px;
  float: right;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Sans', sans-serif;
}
body, p {
  font-family: 'Heebo', sans-serif;
  color: #0C2A52A6;
}

.featuresSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  /* width: 60%; */
  align-self: center;
  margin: auto;
  box-sizing: border-box;
}

.featureSection h5, 
.featureSection p {
  text-align: left;
}

.featureList ul {
  list-style: none; /* Removes the default list styling */
  padding: 0; /* Removes the default padding */
}

.featureList {
  margin: auto;
  width: 50%;
  display: flex;
}

.featureItem {
  display: flex; /* Uses flexbox to align icon and text side by side */
  align-items: center; /* Vertically centers the icon and text */
  gap: 20px; /* Adjust the space between the icon and the text */
}

.featureIcon {
  /* flex: 0 0 auto; Prevents the icon from growing or shrinking */
  max-width: 35px;
  /* align vertically to the top */
  vertical-align: top;
  margin-top: -45px;
}

.featureText {
  flex: 1; /* Allows the text to take up the remaining space */
}



.featureText h5 {
  font-size: 1.2rem; /* Increases the font size of the feature title */
  font-weight: bold; /* Makes the feature title bold */
  text-align: left;
  margin: 0;
}

.featureText p {
  font-size: 1rem; /* Increases the font size of the feature description */
  text-align: left;
  margin: 0;
  /* Add space between the feature paragraphs */
  margin-top: 10px;
  margin-bottom: 10px;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonialImage {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the set dimensions */
  margin-top: 20px; /* Spacing between quote and image */
  margin-bottom: 20px; /* Spacing between image and name */
  /* Add white border to the image */
  border: 4px solid white;
}

.testimonial ul {
  list-style-type: none; /* Removes default list styling */
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust spacing between items as needed */
}

.testimonial {
  /* Semi transparent dark background color */
  background-color: rgba(0, 0, 0, 0.563);
  margin: 20px auto;
  width: 90%;
  padding: 20px;
}

.testimonialQuote {
  font-size: 1.25rem; /* Larger font size for the quote */
  text-align: center;
  margin-bottom: 20px; /* Spacing between quote and the image row */
  font-style: italic;
  max-width: 80%; /* Limits the width of the quote for readability */
  padding: 10px;
  border-radius: 30px;
}

.testimonialName, .testimonialTitle, .testimonialLocation {
  margin: 0; /* Removes default margin from list items */
}

.testimonialName {
  font-weight: bold;
  color: white;
}

.testimonialInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding-left: 10px;
}

/* Additional styling for the name, title, and location for better visual hierarchy */
.testimonialTitle {
  font-size: 0.9rem;
  color: white;
}

.testimonialLocation {
  font-size: 0.8rem;
  color: white; /* Lighter color for location */
}

.thankYouContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  align-self: center;
  margin: auto;
  background-color: #eaeaeab6;
  box-sizing: border-box;
  border-radius: 15px;
  width: 50%; /* Default to 50% width on desktop */
}

.thankYouContainer .heroHeading {
  font-size: 36px; /* Larger font size for the heading */
  padding: 20px; /* Adjust padding */
  width: 90%; /* Increase width percentage for smaller screens */
  box-sizing: border-box; /* Include padding in width calculation */
}

.thankYouContainer .heroParagraph {
  font-size: 18px; /* Larger font size for the paragraph */
  max-width: 80%; /* Limit the width of the paragraph */
  margin: 20px auto; /* Add margin for spacing */
  text-align: center; /* Center align the paragraph */
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .heroHeading {
    font-size: 28px; /* Even smaller font size for mobile screens */
    padding: 10px; /* Adjust padding */
    width: 100%; /* Allow text to take up more space */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .thankYouContainer {
    width: 100%;
    box-sizing: border-box;
  }
  .thankYouContainer .heroHeading {
    font-size: 18px; /* Larger font size for the heading */
    padding: 15px; /* Adjust padding */
    width: 90%; /* Increase width percentage for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .testimonailQuote {
    font-size: .8rem;
  }
  .testimonial {
    width: 100%;
  }
  .testimonialSection {
    width: 100%; /* Full width on mobile */
    max-width: 100%; /* Ensure no max-width restriction */
  }
  .heroContainer {
    width: 100%;
  }
  .pageBG {
    width: 100%;
  }
  .featureList {
    width: 80%;
  }
  
}
/* Responsive adjustments for tablets */
@media (max-width: 932px) {
  .heroHeading {
    font-size: 36px; /* Smaller font size for smaller screens */
    padding: 15px; /* Adjust padding */
    width: 90%; /* Increase width percentage for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .thankYouContainer {
    width: 100%;
    box-sizing: border-box;
  }
  .thankYouContainer .heroHeading {
    padding: 20px; /* Adjust padding */
    width: 90%; /* Increase width percentage for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  .testimonailQuote {
    font-size: 1.2rem;
  }
  .heroContainer {
    box-sizing: border-box;
  }
  .testimonialSection {
    width: 100%; /* Full width on tablets */
    max-width: 100%; /* Ensure no max-width restriction */
  }
  .testimonial {
    max-width: 600px;
    box-sizing: border-box;
    margin: 20px auto;
  }
  .featureList {
    width: 80%;
  }
}